import React, { useContext } from "react"
import axios from "axios"

import { Box, IconButton, Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { AlertContext } from "../../../app/contexts/AlertContext"
import { AdminUsersContext } from "../../../app/contexts/AdminUsersContext"

import AddUserForm from "./AddUserForm.component"

const style = {
  bgcolor: "background.paper",
  border: "1px solid",
  borderColor: "grey.400",
  boxShadow: 24,
  left: "50%",
  maxWidth: "800px",
  padding: { xs: 2, sm: 4, md: 6 },
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
}

const AddUserModal = ({ modalOpen, handleCloseModal, setSearch }) => {
  const { setAlert } = useContext(AlertContext)
  const { addUser } = useContext(AdminUsersContext)

  const handleAddUser = (formValues, isAdmin) => {
    const dbObj = {
      email: formValues.email,
      fname: formValues.fname,
      lname: formValues.lname,
      isAdmin,
      lastCompleted: 0,
      username: formValues.email
    }
    axios.post("/api/firebase/auth/create", {
      email: formValues.email,
      fname: formValues.fname,
      lname: formValues.lname,
      password: formValues.code
    }).then(res => {
      const userId = res.data

      // Add user to rtdb
      axios.post("/api/users/add", {
        userId,
        dbObj
      }).then(res => {     
        // Add new user to store
        addUser({ id: userId, ...dbObj })
        setSearch(formValues.email)
        handleCloseModal()
        setAlert({ message: `User ${formValues.fname} ${formValues.lname} has been added`, severity: "success" })
      }).catch(error => {
        console.log('error creating user in database: ', error.message)
        setAlert({ message: `Error creating user: ${error.message}`, severity: "error" })
        
        // remove auth account that was just created
        axios.post("/api/firebase/auth/delete", { 
          email: formValues.email
        }).then(() => {
          console.log('auth account deleted')
        }).catch(error => {
          console.log('error deleting auth acount', error.message)
        })

      })
    }).catch(error => {
      console.log('error creating auth account', error.response.data.message)
      setAlert({ message: `Error creating user: ${error.response.data.message}`, severity: "error" })
    })
  }

  return (
    <Modal
      open={modalOpen}
      aria-labelledby="user-modal-title"
    >
      <Box sx={style}>
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            top: { xs: 2, sm: 8 },
            right: { xs: 2, sm: 8 },
          }}
        >
          <CloseIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
        <AddUserForm handleCloseModal={handleCloseModal} handleAddUser={handleAddUser} />
      </Box>
    </Modal>
  )
}

export default AddUserModal
