import React, { useContext, useEffect } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
// import { createBrowserHistory } from "history"

import { UserContext } from "../contexts/UserContext"
import AdminUsersProvider from "../contexts/AdminUsersContext"

import Layout from "../../common/Layout.component"
import LoginLayout from "../../common/LoginLayout.component"
import AdminLayout from "../../features/admin/AdminLayout.component"
import NotFoundPage from "../../features/NotFoundPage.component"

import RequireAnon from "./RequireAnon"
import RequireAuth from "./RequireAuth"
import RequireAdmin from "./RequireAdmin"

import pubicRoutes from "./publicRoutes"
import accountRoutes from "./accountRoutes"
import previewRoutes from "./previewRoutes"
import adminRoutes from "./adminRoutes"
import privateRoutes from "./privateRoutes"

// export const history = createBrowserHistory()

const AppRouter = () => {
  const navigate = useNavigate()
  const { uid, safetyCertified } = useContext(UserContext)
  useEffect(() => {
    if (uid && !safetyCertified) navigate("/safety-certification")
  }, [uid, safetyCertified, navigate])

  return (
    <Routes>
      <Route element={<Layout />}>
        {pubicRoutes.map((route, i) => (
          // No Auth requirements. Standard Layout
          <Route key={i} path={route.path} element={<route.component />} />
        ))}
      </Route>
      <Route
        element={
          <RequireAnon>
            <LoginLayout />
          </RequireAnon>
        }
      >
        {accountRoutes.map((route, i) => (
          // Must be anonymous. Login Layout
          <Route key={i} path={route.path} element={<route.component />} />
        ))}
      </Route>
      <Route
        element={
          <RequireAnon>
            <Layout />
          </RequireAnon>
        }
      >
        {previewRoutes.map((route, i) => (
          // Must be anonymous. Standard Layout
          <Route key={i} path={route.path} element={<route.component />} />
        ))}
      </Route>
      <Route
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        {privateRoutes.map((route, i) => (
          // Must be authenticated. Standard Layout
          <Route key={i} path={route.path} element={<route.component />} />
        ))}
      </Route>
      <Route
        element={
          <RequireAuth>
            <RequireAdmin>
              <AdminUsersProvider>
                <AdminLayout />
              </AdminUsersProvider>
            </RequireAdmin>
          </RequireAuth>
        }
      >
        {adminRoutes.map((route, i) => (
          // Must be authenticated. Admin Layout
          <Route key={i} path={route.path} element={<route.component />} />
        ))}
      </Route>
      <Route element={<Layout />}>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}

export default AppRouter
